<template>
    <div>
        <div id="auth-window">

            <template v-if="app">
                <img id="profile-picture" :src="app.picture" alt="">
                <h3 id="user-name-preview">{{ app.name }}</h3>
                <h4>Wants to access your account</h4>

                <div id="permissions">
                    <div v-for="(scopePreview, i) of scopePreviewList" :key="i">
                        <svg v-if="scopePreview.icon == 'personal'" xmlns="http://www.w3.org/2000/svg" id="artwork"
                             width="512" height="512" viewBox="0 0 64 64">
                            <circle cx="20" cy="30.5" r="3" style="fill:#4542F4"/>
                            <path
                                d="M32,18.5H8A1.50008,1.50008,0,0,0,6.5,20V50A1.50008,1.50008,0,0,0,8,51.5H32A1.50007,1.50007,0,0,0,33.5,50V20A1.50007,1.50007,0,0,0,32,18.5Zm-3,27A1.50008,1.50008,0,0,1,27.5,44a7.5,7.5,0,0,0-15,0,1.5,1.5,0,0,1-3,0,10.50379,10.50379,0,0,1,6.04547-9.49445,6,6,0,1,1,8.90906,0A10.50379,10.50379,0,0,1,30.5,44,1.50007,1.50007,0,0,1,29,45.5Z"
                                style="fill:#4542F4"/>
                            <path
                                d="M59,6.5H11A4.50491,4.50491,0,0,0,6.5,11v1.5H5A4.50491,4.50491,0,0,0,.5,17V53A4.50491,4.50491,0,0,0,5,57.5H53A4.50491,4.50491,0,0,0,57.5,53V51.5H59A4.50491,4.50491,0,0,0,63.5,47V11A4.50491,4.50491,0,0,0,59,6.5ZM54.5,53A1.50164,1.50164,0,0,1,53,54.5H5A1.50164,1.50164,0,0,1,3.5,53V17A1.50164,1.50164,0,0,1,5,15.5H53A1.50164,1.50164,0,0,1,54.5,17Zm6-6A1.50164,1.50164,0,0,1,59,48.5H57.5V17A4.50491,4.50491,0,0,0,53,12.5H9.5V11A1.50164,1.50164,0,0,1,11,9.5H59A1.50164,1.50164,0,0,1,60.5,11Z"/>
                            <path d="M50,24.5H38a1.5,1.5,0,0,0,0,3H50a1.5,1.5,0,0,0,0-3Z"/>
                            <path d="M50,33.5H38a1.5,1.5,0,0,0,0,3H50a1.5,1.5,0,0,0,0-3Z"/>
                            <path d="M50,42.5H38a1.5,1.5,0,0,0,0,3H50a1.5,1.5,0,0,0,0-3Z"/>
                        </svg>
                        <svg v-else-if="scopePreview.icon == 'other'" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24">
                            <path fill="#656565"
                                  d="M10,13H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,20H4V15H9ZM21,2H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H15V4h5Zm1,4H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm-1,7H15V15h5ZM10,2H3A1,1,0,0,0,2,3v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,10,2ZM9,9H4V4H9Z"/>
                        </svg>
                        <img v-else :src="scopePreview.icon"/>
                        <div>
                            <h1>{{ scopePreview.title }}</h1>
                            <p>{{ scopePreview.scopes.join(", ") }}
                            </p>
                        </div>
                    </div>
                </div>

                <br>
                <a @click="accept" class="button" id="login-button">Accept</a>
            </template>
            <template v-else-if="error == 'NamespacePermissionsDenied'">
                <div>
                    <h3 style="margin-bottom: 15px; opacity: 0.5">This App is bound to a namespace.</h3>
                    <h5 style="margin-bottom: 5px; opacity: .7">Switch User:</h5>
                </div>
            </template>

            <router-link :to="'/switch?redirect='+encodeURIComponent($route.fullPath)+'&temp=true'"
                         class="button user-select">
                <img :src="$store.state.user.profile_picture">
                <!-- :style="{border: '2px solid '+$store.state.user.favorite_color}" -->
                <span>{{ $store.state.user.name }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        app: null,
        redirectUrl: null,
        clientId: 0,
        scopes: "",
        state: null,
        scopePreviewList: [],
        error: null
    }),
    created() {
        if (
            this.$route.query.redirect_uri &&
            (
                this.$route.query.redirect_uri.startsWith("https://") ||
                this.$route.query.redirect_uri.startsWith("http://")
            )
        ) {
            this.redirectUrl = this.$route.query.redirect_uri;
        }
        if (this.$route.params.id)
            this.clientId = this.$route.params.id
        if (this.$route.query.client_id)
            this.clientId = this.$route.query.client_id

        if (this.$route.query.state)
            this.state = this.$route.query.state

        if (this.$route.query.scope)
            this.scopes = this.$route.query.scope.replaceAll(" ", ",");


        this.collectScopes()
        this.load()
    },
    methods: {
        accept() {
            if (this.$route.query.response_type == 'token') {
                if (this.app.urls.includes(this.redirectUrl)) {
                    this.apiClient.get(`/api/v2/authorization/oauth2/apps/${this.clientId}/grant/implicit`, {
                        scope: this.scopes
                    })
                        .then(res => res.json())
                        .then(res => {
                            if (res.success) {
                                window.location = this.redirectUrl
                                    + "#access_token=" + encodeURIComponent(res.access_token)
                                    + "&token_type=Bearer"
                                    + (this.state ? '&state=' + this.state : "")
                                    + (this.$route.query.scope ? '&scope=' + this.$route.query.scope : '');
                            }
                        });
                } else {
                    alert("Error. Invalid response url!")
                }
            } else {
                this.apiClient.get("/api/v2/authorization/oauth2/apps/" + this.clientId + "/grant", {
                    scope: this.scopes
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success) {
                            window.location = this.redirectUrl + "?code=" + res.code + (this.state ? '&state=' + this.state : "") + (this.$route.query.scope ? '&scope=' + encodeURIComponent(this.$route.query.scope) : '');
                        }
                    });
            }
        },
        load() {
            this.apiClient.get("/api/v2/authorization/oauth2/apps/" + this.clientId)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.app = res;

                        if (!this.redirectUrl)
                            this.redirectUrl = res.default_redirect_url

                        if (res.redirect_url)
                            window.location = res.redirect_url + (this.state ? '&state=' + encodeURIComponent(this.state) : "") + (this.$route.query.scope ? '&scope=' + encodeURIComponent(this.$route.query.scope) : '');
                    } else {
                        this.error = res.exception
                    }
                });
        },
        async collectScopes() {
            this.scopePreviewList = []

            const personalScopes = []
            const otherScopes = []
            const oAuth2AppsScopes = {}

            for (const scope of this.scopes.split(",")) {
                if (scope.startsWith("user")) {
                    personalScopes.push(scope)
                } else if (scope.includes("|")) {
                    const split = scope.split("|");
                    if (!oAuth2AppsScopes[split[0]])
                        oAuth2AppsScopes[split[0]] = []
                    oAuth2AppsScopes[split[0]].push(split[1])
                } else {
                    otherScopes.push(scope)
                }
            }

            if (personalScopes.length > 0) {
                this.scopePreviewList.push({
                    icon: 'personal',
                    title: 'Personal Information',
                    scopes: personalScopes
                })
            }
            if (otherScopes.length > 0) {
                this.scopePreviewList.push({
                    icon: 'other',
                    title: 'Other',
                    scopes: otherScopes
                })
            }

            for (const [name, scopes] of Object.entries(oAuth2AppsScopes)) {
                let app = {};
                try {
                    app = (await this.apiClient.get(`/api/v2/authorization/oauth2/apps/${encodeURIComponent(name)}`)).json()
                } catch (e) {
                    //
                }
                this.scopePreviewList.push({
                    icon: app.picture || 'other',
                    title: `${app.name || name} (OAuth2 App)`,
                    scopes: scopes
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#permissions {
    > div {
        margin-top: 6px;

        svg, img {
            width: 50px;
            height: 50px;
            padding: 7px;
            background: #00000011;
            border-radius: 13px;
            display: inline-block;
            vertical-align: middle;
        }

        div {
            display: inline-block;
            width: calc(100% - 60px);
            margin-left: 10px;
            vertical-align: middle;

            h1 {
                font-size: 20px;
                color: #434343;
            }

            p {
                color: #656565;
                font-size: 14px;
                margin: 0px;
            }
        }
    }
}
</style>